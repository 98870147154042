<template>
  <div>
    <div>
      <v-btn class="mt-6 mb-4" style="float: right">
        <v-icon>mdi-plus</v-icon>
        Opret ny fil
      </v-btn>
    </div>
    <br /><br /><br /><br />
    <div>
      <v-data-table :items="files" :headers="headers">
        <template v-slot:item.actions="{ item }">
          <div>
            <v-icon left small>
              mdi-pencil
            </v-icon>
            <v-icon left small>
              mdi-delete
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailManagement",
  computed: {
    headers() {
      return [
        {
          text: "Filnavn",
          value: "name"
        },
        {
          text: "Beskrivelse",
          value: "desc"
        },
        {
          text: "Størrelse",
          value: "size"
        },
        { text: "", value: "actions", sortable: false }
      ];
    }
  },
  data: () => ({
    files: [
      {
        name: "Fil A",
        desc: "Fil, der kan bruges som en vedhæftet fil til en e-mail",
        size: "5 MB"
      },
      {
        name: "Fil B",
        desc: "Fil, der kan bruges som en vedhæftet fil til en e-mail",
        size: "1 MB"
      }
    ]
  })
};
</script>

<style scoped></style>
