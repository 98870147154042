<template>
  <v-card>
    <v-card-title>
      <slot name="title">
        {{ $t("error") }}
      </slot>
    </v-card-title>
    <v-card-text>
      <v-alert type="error">
        <slot name="text"></slot>
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn @click="ok" class="primary" data-testid="UserErrorOK">
        {{ $t("ok") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "UserError",

  methods: {
    ok() {
      this.$emit("ok");
    }
  }
};
</script>
