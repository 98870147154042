<template>
  <v-btn
    @click="onClick"
    :outlined="outlined"
    :small="small"
    :class="{ info: info, primary: primary }"
    :block="block"
    data-testid="btnCustomBtn"
  >
    <v-icon left v-if="icon">
      {{ icon }}
    </v-icon>
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  name: "CustomBtn",

  props: {
    icon: {
      type: String
    },
    text: {
      type: String
    },
    info: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};
</script>
