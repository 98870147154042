<template>
  <v-container>
    <v-card>
      <v-card-title> </v-card-title>
      <v-card-text>
        <v-tabs v-model="activeTab">
          <v-tab :key="1">{{ $t("soundFiles") }}</v-tab>
          <v-tab :key="2">{{ $t("emailFiles") }}</v-tab>
          <v-tab-item :key="1">
            <FileManagement :is-audio-file="true" />
          </v-tab-item>
          <v-tab-item :key="2">
            <FileManagement :is-audio-file="false" />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import FileManagement from "@/components/files/FileManagement";
import EmailManagement from "@/components/files/EmailFileManagement";
export default {
  name: "Files",
  components: { EmailManagement, FileManagement },

  data: () => ({
    activeTab: 0
  })
};
</script>